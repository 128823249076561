.app-theme--sagedark {
  .app {
    background-color: #c7d5de;
    background-image: url('../images/background-dark.jpg');
    background-size : 100% 750px;
  }

  .top-logo__img {
    display: none;
  }

  .top-logo__img--light {
    display: inline-block;
  }

  .greeting,
  .greeting strong {
    color: white;
  }

  .section__title {
    color: white;
  }

  .icon {
    &--settings::after {
      color: white;
    }
  }

  .feed__more {
    color: white;
  }
}