@keyframes wave {
  0% {
    box-shadow: 0 -10vh 20vh #0c0;
    top       : -100vh;
  }

  48%,
  52% {
    box-shadow: 0 -10vh 20vh #090;
  }

  50% {
    box-shadow: 0 -10vh 20vh #060;
  }

  100% {
    box-shadow: 0 -10vh 20vh #0c0;
    top       : 200vh;
  }
}

.app-theme--pipboy {
  .app {
    background-image: radial-gradient(rgb(19, 44, 19), rgba(9, 21, 9, 1));
    color           : white;
    overflow        : hidden;
    position        : relative;

    &:before,
    &:after {
      content       : "";
      position      : absolute;
      pointer-events: none;
    }

    &:before {
      animation: wave 10s infinite ease-in-out;
      height   : 20vh;
      right    : 0;
      left     : 0;
      opacity  : .5;
      z-index  : 1;
    }

    &:after {
      background-image: linear-gradient(transparent, transparent 3px, rgba(17, 39, 17, 1));
      background-size : 4px 4px;
      top             : 0;
      right           : 0;
      bottom          : 0;
      left            : 0;
      pointer-events  : none;
      z-index         : 2;
      opacity         : 0.2;
    }
  }

  .screen--dashboard * {
    color      : inherit;
    font-family: monospace;
    font-weight: normal;
  }

  .settings {
    color: black;
  }

  .dropdown {
    color: rgb(156, 154, 154);
  }

  .top-logo {
    display: none;
  }

  .greeting {
    color      : #41ff7b;
    text-shadow: 0 0 10px #41ff7b;
    font-size  : 18px;

    strong {
      font-weight: normal;
      color      : white;
    }
  }

  .global-nav__settings {
    .icon {
      width: auto;

      &::after {
        font-size  : 18px;
        color      : #41ff7b;
        text-shadow: 0 0 10px #41ff7b;
        content    : 'settings';
        font-family: monospace;
      }
    }

    &:hover {
      transform: none;
    }
  }

  .global-nav .global-nav__profile img {
    filter: sepia(100%) saturate(300%) brightness(70%) hue-rotate(73deg);
  }

  .section__title {
    color      : #41ff7b;
    text-shadow: 0 0 10px #41ff7b;
    font-size  : 18px;
  }

  .search__input {
    background: transparent;
    border    : 2px green dashed;
    color     : white;
    box-shadow: 0 0 10px 0 #235823, inset 0 0 10px 0 #235823;
  }

  .search__engine {
    background: transparent;

    >button {
      background: transparent;
      border    : 2px solid green;
      box-shadow: 0 0 10px 0 #235823, inset 0 0 10px 0 #235823;
    }
  }

  .search__submit {
    background: transparent;

    >button::after {
      color: green;
    }
  }

  .section {
    padding : 15px 0;
    position: relative;

    &:before {
      content   : '';
      display   : block;
      width     : 100%;
      border-top: 1px solid green;
      box-shadow: 0 0 15px 0 #30c530;
      position  : absolute;
      top       : 0;
    }
  }

  .app-card {
    padding   : 0;
    background: transparent;
    box-shadow: none;

    &:hover {
      transform: none;
    }

    &__logo {
      display: none;
    }

    &__title {
      color          : #26e445;
      text-shadow    : 0 0 10px #41ff7b;
      font-size      : 18px;
      text-decoration: underline;
    }

    &__description {
      color      : white;
      font-size  : 12px;
      font-family: monospace;
      font-style : italic;
      text-shadow: 0 0 10px #bffdd2;
    }
  }

  .feed {
    &__image {
      display: none;
    }

    &__content {
      background: transparent;
      box-shadow: none;
      padding   : 0;

      &:hover {
        box-shadow: none;
      }
    }

    &__title,
    &__text,
    &__datetime {
      text-shadow: 0 0 10px #bffdd2;
    }
  }

  .banner {
    background: transparent;
    min-height: 0;
    padding   : 0;

    &__content {
      max-width: none;
      margin   : 0;
      width    : 100%;
    }

    &__text {
      font-size  : 18px;
      text-shadow: 0 0 10px #bffdd2;
    }

    &__button {
      background     : transparent;
      color          : white;
      text-shadow    : 0 0 10px #bffdd2;
      text-decoration: underline;
      padding        : 0;
      text-align     : left;

      &:hover {
        background: transparent;
      }
    }
  }
}