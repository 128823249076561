@font-face {
  font-family: 'fontello';
  src: url('./font/fontello.eot?92061533');
  src: url('./font/fontello.eot?92061533#iefix') format('embedded-opentype'),
       url('./font/fontello.woff2?92061533') format('woff2'),
       url('./font/fontello.woff?92061533') format('woff'),
       url('./font/fontello.ttf?92061533') format('truetype'),
       url('./font/fontello.svg?92061533#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin fontello ($icon) {
  display: inline-block;
  font-family: 'fontello';
  content: $icon;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  font-style: normal;
  font-weight: normal;

  .ie & {
    padding-top: 1px;
    padding-bottom: 1px;
  }
}

$icon-search: '\e800';
$icon-gear: '\e801';
$icon-eye: '\e802';
$icon-eye-off: '\e803';
$icon-drag: '\e804';
$icon-cancel-outline: '\e805';
$icon-down-tri: '\e806';
$icon-left-tri: '\e807';
$icon-right-tri: '\e808';
$icon-up-tri: '\e809';
$icon-spin: '\e834';
$icon-angle-left: '\f104';
$icon-angle-right: '\f105';
$icon-angle-up: '\f106';
$icon-angle-down: '\f107';