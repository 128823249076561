@import '../fonts/fontello/fontello';

$breakpoint-s: 420px;
$breakpoint-m: 768px;
$breakpoint-l: 980px;
$breakpoint-xl: 1366px;
$breakpoint-xxl: 1600px;
$breakpoint-xxxl: 1950px;

$global-max-width: $breakpoint-xxxl;

$base-box-shadow: 0px 9px 56px 0px rgba(15, 17, 22, 0.06), 0px 0px 21px 0px rgba(15, 17, 22, 0.02);

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform:rotate(360deg);
  }
}

@mixin box {
  box-shadow: $base-box-shadow;
  border-radius: 2px;
  background: white;
  overflow: hidden;
}

@mixin clearfix {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

img[data-load-failed="true"] {
  display: none;
}

.button {
  cursor: pointer;
  font-family: 'Sofia Pro', sans-serif;
  background: #556478;
  font-size: 14px;
  font-weight: bold;
  color: white;
  border-radius: 2px;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  transition: background-color 0.5s, transform 0.05s;

  &:hover {
    background: #272e38;
  }

  &:active {
    transform: scale3d(1.05, 1.05, 1);
  }

  @media all and (min-width: $breakpoint-l) {
    font-size: 16px;
  }

  @media all and (min-width: $breakpoint-xl) {
    font-size: 21px;
  }
}

.dropdown {
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: white;
  border: 2px solid #556478;
  border-radius: 4px;
  box-shadow: 0 0 16px 2px rgba(0,0,0,0.1);
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transform: translate3d(0, -8px, 0);
  transition: opacity 0.3s, max-height 0s 0.2s, transform 0.2s, padding 0s 0.2s;
  z-index: 1;

  li {
    cursor: pointer;
    white-space: nowrap;
    padding: 9px 12px;
    border-bottom: 1px solid #e4e4e4;
    transition: color 0.5s;
    font-family: 'Sofia Pro', sans-serif;
    font-weight: 600;
    color: #acb1b9;

    &:hover {
      color: #8d939b;
    }

    &[aria-selected="true"] {
      color: #556478;
    }

    &:last-child {
      border: 0;
    }
  }

  &[aria-expanded="true"] {
    opacity: 1;
    max-height: 10000px;
    overflow: visible;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.3s, max-height 0s, transform 0.2s, padding 0s;
    padding: 4px;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  background-repeat: no-repeat;
  background-size: contain;
}