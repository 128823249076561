.redirect {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f0f8ff;

  &__content {
    max-width: 660px;
    padding: 15px;
  }

  &__title {
    font-family: 'Sofia Pro', sans-serif;
    font-weight: bold;
    font-size: 32px;
  }

  &__text {
    color: #697177;
  }

  &__button {
    margin-top: 25px;
    padding: 10px 20px;
    font-size: 18px;
  }
}