@font-face {
    font-family: 'Sofia Pro';
    src: url('subset-SofiaProRegular.woff2') format('woff2'),
        url('subset-SofiaProRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('subset-SofiaPro-SemiBoldItalic.woff2') format('woff2'),
        url('subset-SofiaPro-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('subset-SofiaPro-BoldItalic.woff2') format('woff2'),
        url('subset-SofiaPro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('subset-SofiaPro-LightItalic.woff2') format('woff2'),
        url('subset-SofiaPro-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('subset-SofiaPro-Italic.woff2') format('woff2'),
        url('subset-SofiaPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('subset-SofiaPro-Bold.woff2') format('woff2'),
        url('subset-SofiaPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('subset-SofiaPro-Light.woff2') format('woff2'),
        url('subset-SofiaPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro Semi';
    src: url('subset-SofiaPro-SemiBold.woff2') format('woff2'),
        url('subset-SofiaPro-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

