// Styles for transitions between dashboard screens

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.screen--login {
  &.screen-exit {
    overflow: hidden;

    .login {
      opacity: 1;
      transform: scale3d(1,1,1);
      transition: opacity 0.3s, transform 0.2s ease-in;
    }

    .login__content {
      opacity: 0;
      max-height: 0;
    }

    &::after {
      opacity: 1;
      max-height: 1000px;
    }
  }

  &.screen-exit-active {
    .login {
      opacity: 0;
      transform: scale3d(1.4,1.4,1);
    }
  }
}

.screen--dashboard {
  &.screen-enter {
    opacity: 0;

    .screen-transition-target {
      opacity: 0;
    }
  }

  &.screen-enter-active {
    opacity: 1;

    .screen-transition-target {
      animation-name: fadeInDown;
      animation-duration: 0.4s;
      animation-timing-function: ease-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    .greeting { animation-delay: 0.1s; }
    .global-nav { animation-delay: 0.2s; }
    .section--top { animation-delay: 0.3s; }
    .section--apps { animation-delay: 0.4s; }
  }
}